<template>
  <div
    class="w-full bg-hero">
    <div class="flex justify-center py-2 align-center">
      <a
        href="https://www.linkedin.com/in/vachetvirginie/"
        target="linkedin"
        class="m-2 transition duration-300 ease-in-out text-true-gray-900"><svg
          viewBox="0 0 24 24"
          style="width: 24px; height: 24px;"><path
            fill="currentColor"
            d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z" /></svg></a>
      <a
        href="https://github.com/VachetVirginie"
        target="github"
        class="m-2 transition duration-300 ease-in-out text-true-gray-900"><svg
          viewBox="0 0 24 24"
          style="width: 24px; height: 24px;"><path
            fill="currentColor"
            d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" /></svg></a>
      <a
        href="https://www.instagram.com/waspyi/"
        target="instagram"
        class="m-2 transition duration-300 ease-in-out text-true-gray-900"><svg
          viewBox="0 0 24 24"
          style="width: 23px; height: 23px;"><path
            fill="currentColor"
            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg></a>
    </div>
    <div class="w-full px-10 pb-5 text-xs text-center md:text-sm text-true-gray-600 ">
      Virginie Vachet {{ thisYear }}© <span class="text-true-gray-500"> | built with <span
        class="font-semi-bold text-primary">Tailwindcss 2</span>,
        <span
          class="font-semi-bold text-primary">Vuejs 3</span> &amp;
        <span class="font-semi-bold text-primary"> Passion</span></span>
    </div>
  </div>
</template>

<script>
export default {
    name: "MainFooter",
    data() {
        return {
            thisYear: new Date().getFullYear(),
        };
    },
    created() {
        let carbonbadge = document.createElement("script");    carbonbadge.setAttribute("src","//unpkg.com/website-carbon-badges@1.1.3/b.min.js");
        document.head.appendChild(carbonbadge);
    }
};
</script>

<style>
.bg-hero {
  background: center / cover no-repeat url("assets/patternFooter.svg");
}

.carbonbadge {
  @layer text-primary
}

#wcb.wcb-d #wcb_2 {
    @layer text-primary
}
</style>
