<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-linejoin="round"
    stroke-linecap="round"
    fill="none"
    role="presentation"
    class="ml-0.5 w-6 h-6 stroke-2"><line
      x1="7"
      y1="17"
      x2="17"
      y2="7" /> <polyline points="7 7 17 7 17 17" />
  </svg>
</template>
