<template>
  <svg
    width="300"
    height="84"
    viewBox="0 0 300 84"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      :class="colorClass"
      cx="6"
      cy="78"
      r="6"
      transform="rotate(-180 6 78)" />
    <circle
      :class="colorClass"
      cx="30"
      cy="78"
      r="6"
      transform="rotate(-180 30 78)" />
    <circle
      :class="colorClass"
      cx="54"
      cy="78"
      r="6"
      transform="rotate(-180 54 78)" />
    <circle
      :class="colorClass"
      cx="78"
      cy="78"
      r="6"
      transform="rotate(-180 78 78)" />
    <circle
      :class="colorClass"
      cx="102"
      cy="78"
      r="6"
      transform="rotate(-180 102 78)" />
    <circle
      :class="colorClass"
      cx="126"
      cy="78"
      r="6"
      transform="rotate(-180 126 78)" />
    <circle
      :class="colorClass"
      cx="150"
      cy="78"
      r="6"
      transform="rotate(-180 150 78)" />
    <circle
      :class="colorClass"
      cx="174"
      cy="78"
      r="6"
      transform="rotate(-180 174 78)" />
    <circle
      :class="colorClass"
      cx="198"
      cy="78"
      r="6"
      transform="rotate(-180 198 78)" />
    <circle
      :class="colorClass"
      cx="222"
      cy="78"
      r="6"
      transform="rotate(-180 222 78)" />
    <circle
      :class="colorClass"
      cx="246"
      cy="78"
      r="6"
      transform="rotate(-180 246 78)" />
    <circle
      :class="colorClass"
      cx="270"
      cy="78"
      r="6"
      transform="rotate(-180 270 78)" />
    <circle
      :class="colorClass"
      cx="294"
      cy="78"
      r="6"
      transform="rotate(-180 294 78)" />
    <circle
      :class="colorClass"
      cx="6"
      cy="54"
      r="6"
      transform="rotate(-180 6 54)" />
    <circle
      :class="colorClass"
      cx="30"
      cy="54"
      r="6"
      transform="rotate(-180 30 54)" />
    <circle
      :class="colorClass"
      cx="54"
      cy="54"
      r="6"
      transform="rotate(-180 54 54)" />
    <circle
      :class="colorClass"
      cx="78"
      cy="54"
      r="6"
      transform="rotate(-180 78 54)" />
    <circle
      :class="colorClass"
      cx="102"
      cy="54"
      r="6"
      transform="rotate(-180 102 54)" />
    <circle
      :class="colorClass"
      cx="126"
      cy="54"
      r="6"
      transform="rotate(-180 126 54)" />
    <circle
      :class="colorClass"
      cx="150"
      cy="54"
      r="6"
      transform="rotate(-180 150 54)" />
    <circle
      :class="colorClass"
      cx="174"
      cy="54"
      r="6"
      transform="rotate(-180 174 54)" />
    <circle
      :class="colorClass"
      cx="198"
      cy="54"
      r="6"
      transform="rotate(-180 198 54)" />
    <circle
      :class="colorClass"
      cx="222"
      cy="54"
      r="6"
      transform="rotate(-180 222 54)" />
    <circle
      :class="colorClass"
      cx="246"
      cy="54"
      r="6"
      transform="rotate(-180 246 54)" />
    <circle
      :class="colorClass"
      cx="270"
      cy="54"
      r="6"
      transform="rotate(-180 270 54)" />
    <circle
      :class="colorClass"
      cx="294"
      cy="54"
      r="6"
      transform="rotate(-180 294 54)" />
    <circle
      :class="colorClass"
      cx="6"
      cy="30"
      r="6"
      transform="rotate(-180 6 30)" />
    <circle
      :class="colorClass"
      cx="30"
      cy="30"
      r="6"
      transform="rotate(-180 30 30)" />
    <circle
      :class="colorClass"
      cx="54"
      cy="30"
      r="6"
      transform="rotate(-180 54 30)" />
    <circle
      :class="colorClass"
      cx="78"
      cy="30"
      r="6"
      transform="rotate(-180 78 30)" />
    <circle
      :class="colorClass"
      cx="102"
      cy="30"
      r="6"
      transform="rotate(-180 102 30)" />
    <circle
      :class="colorClass"
      cx="126"
      cy="30"
      r="6"
      transform="rotate(-180 126 30)" />
    <circle
      :class="colorClass"
      cx="150"
      cy="30"
      r="6"
      transform="rotate(-180 150 30)" />
    <circle
      :class="colorClass"
      cx="174"
      cy="30"
      r="6"
      transform="rotate(-180 174 30)" />
    <circle
      :class="colorClass"
      cx="198"
      cy="30"
      r="6"
      transform="rotate(-180 198 30)" />
    <circle
      :class="colorClass"
      cx="222"
      cy="30"
      r="6"
      transform="rotate(-180 222 30)" />
    <circle
      :class="colorClass"
      cx="246"
      cy="30"
      r="6"
      transform="rotate(-180 246 30)" />
    <circle
      :class="colorClass"
      cx="270"
      cy="30"
      r="6"
      transform="rotate(-180 270 30)" />
    <circle
      :class="colorClass"
      cx="294"
      cy="30"
      r="6"
      transform="rotate(-180 294 30)" />
    <circle
      :class="colorClass"
      cx="6"
      cy="5.99998"
      r="6"
      transform="rotate(-180 6 5.99998)" />
    <circle
      :class="colorClass"
      cx="30"
      cy="5.99998"
      r="6"
      transform="rotate(-180 30 5.99998)" />
    <circle
      :class="colorClass"
      cx="54"
      cy="5.99998"
      r="6"
      transform="rotate(-180 54 5.99998)" />
    <circle
      :class="colorClass"
      cx="78"
      cy="5.99998"
      r="6"
      transform="rotate(-180 78 5.99998)" />
    <circle
      :class="colorClass"
      cx="102"
      cy="5.99998"
      r="6"
      transform="rotate(-180 102 5.99998)" />
    <circle
      :class="colorClass"
      cx="126"
      cy="5.99998"
      r="6"
      transform="rotate(-180 126 5.99998)" />
    <circle
      :class="colorClass"
      cx="150"
      cy="5.99998"
      r="6"
      transform="rotate(-180 150 5.99998)" />
    <circle
      :class="colorClass"
      cx="174"
      cy="5.99999"
      r="6"
      transform="rotate(-180 174 5.99999)" />
    <circle
      :class="colorClass"
      cx="198"
      cy="5.99999"
      r="6"
      transform="rotate(-180 198 5.99999)" />
    <circle
      :class="colorClass"
      cx="222"
      cy="5.99999"
      r="6"
      transform="rotate(-180 222 5.99999)" />
    <circle
      :class="colorClass"
      cx="246"
      cy="5.99999"
      r="6"
      transform="rotate(-180 246 5.99999)" />
    <circle
      :class="colorClass"
      cx="270"
      cy="6"
      r="6"
      transform="rotate(-180 270 6)" />
    <circle
      :class="colorClass"
      cx="294"
      cy="6"
      r="6"
      transform="rotate(-180 294 6)" />
  </svg>
</template>

<script>
export default {
    name: "DotsSvg",
    props: {
        color: {
            type: String,
            default: "secondary"
        }
    },
    computed: {
        colorClass() {
            const colorMap = {
                secondary: "fill-secondary",
            };
            return colorMap[this.color] || colorMap.secondary;
        }
    }
};
</script>
